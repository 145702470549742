class UserProfileModel {
	public id: number;
	public first_name: string;
	public last_name: string;
	public email: string;
	public phone_number: string;
	public id_number: string;
	public address: string;
	public join_date: string;
	public user: number;
}

export default UserProfileModel;
