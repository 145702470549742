import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Redux/UserContext";
import { Link, useNavigate } from "react-router-dom";
import "./ProfileDetails.css";
import Loading from "../../SharedArea/Loading/Loading";

function ProfileDetails(): JSX.Element {
	const context = useContext(UserContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		if (context.profile.first_name === "") {
			navigate("/edit_profile");
		} else {
			setIsLoading(false);
		}
	}, [context.profile.first_name, navigate ]);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div className="ProfileDetails">
			<h1>פרופיל</h1>
			<table id="profile">
				<tbody className="main-table">
					<tr>
						<td>דואר אלקטרוני:</td>
						<td>{context.profile.email}</td>
					</tr>
					<tr>
						<td>שם:</td>
						<td>{context.profile.first_name} {context.profile.last_name}</td>
					</tr>
				</tbody>
			</table>
			<Link to={"/edit_profile/"}>
				<button className="button-29">עריכת פרופיל</button>
			</Link>
			<br />
			<br />
			<Link to={"/logout"} className="logoutButton">
				<i className="fas fa-sign-out-alt"> </i> התנתקות
			</Link>
		</div>
	);
}

export default ProfileDetails;
