import { useDispatch, useSelector } from "react-redux";
import "./Tracky.css";
import { OrdersDispatch, OrdersState } from "../../Redux/OrdersContext";
import OrderModel from "../../Models/OrderModel";
import { changePickedOrder, setInputFocusTyping } from "../../Redux/slicers/ordersSlicer";
import { useEffect, useRef, useState } from "react";


function Tracky(): JSX.Element {

    const allOrders = useSelector((state: OrdersState) => state.orders.value);
    const orders = useSelector((state: OrdersState) => state.orders.orders);
    const focusTyping = useSelector((state: OrdersState) => state.orders.inputFocusTyping);
    const dispatch: OrdersDispatch = useDispatch();
    const hebBranches = ["הזמנות דחופות", "ביג", "דיסק קלאב", "אייסמול", "רד", "טיילת", "משלוח לבית הלקוח באילת", "משלוח לבית הלקוח", "מחסן ראשי"];
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchedOrders, setSearchedOrders] = useState<OrderModel[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const branches = [{
        "id": 0,
        "name": "הזמנות דחופות"
    },
    {
        "id": 1,
        "name": "ביג"
    },
    {
        "id": 2,
        "name": "דיסק קלאב"
    },
    {
        "id": 3,
        "name": "אייסמול"
    },
    {
        "id": 4,
        "name": "רד"
    },
    {
        "id": 5,
        "name": "טיילת"
    },
    {
        "id": 6,
        "name": "משלוח לבית הלקוח באילת"
    },
    {
        "id": 7,
        "name": "משלוח לבית הלקוח"
    },
    {
        "id": 8,
        "name": "מחסן ראשי"
    },
    ];

    const branchMap: Record<number, string> = {
        0: 'urgent',
        1: 'big',
        2: 'klb',
        3: 'ice',
        4: 'red',
        5: 'tlt',
        6: 'eilat_delivery',
        7: 'delivery',
        8: 'main',  // main warehouse

    };

    const getBranchName = (branchKey: string) => {
        return branches.find(branch => branchMap[branch.id] === branchKey)?.name || 'Main Branch';
    };

    const formatDate = (expense_date: string) => {
        const date = new Date(expense_date);
        const startDay = date.getDate().toString().padStart(2, "0");
        let startMonth = date.getMonth() + 1;
        // let startYear = date.getFullYear();

        // Format startMonth and endMonth with leading zeros
        const startMonthStr = startMonth.toString().padStart(2, "0");

        // Extract the last two digits of the startYear and endYear
        // const startYearStr = startYear.toString().slice(-2);

        return `${startDay}/${startMonthStr}`;
    };

    const showEditOrder = (order: OrderModel) => {
        dispatch(changePickedOrder(order));
        const editOrder = document.getElementById("EditOrder");
        if (editOrder) {
            editOrder.classList.add("visible");
        }
        dispatch(setInputFocusTyping(false));
    };

    const isReadyNotPaid = (order: OrderModel) => {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 1);
        if (order.pickup_branch !== 7 && order.is_ready_for_pickup && !order.is_paid && new Date(order.pickup_date) < twoDaysAgo) {
            return true;
        } else if (!order.is_ready_for_pickup && !order.is_paid && new Date(order.pickup_date) < twoDaysAgo) {
            return false;
        } else { return false; }
    };

    const getSearchedOrders = (searchedText: string) => {
        if (searchedText.length < 3) {
            setSearchedOrders([]);
            return;
        }
        const searchedOrders: OrderModel[] = [];
        allOrders.forEach(order => {
            if (order.customer_name.toLowerCase().includes(searchedText) || order.order_number.toString().includes(searchedText) || order.description.toLowerCase().includes(searchedText.toLowerCase())) {
                searchedOrders.push(order);
            }
        });
        setSearchedOrders(searchedOrders.sort((a, b) => b.pickup_date.localeCompare(a.pickup_date)));
    };
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        getSearchedOrders(e.target.value);

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }, 0); 
    };

    useEffect(() => {
        console.log("focusTyping", focusTyping);
        const handleGlobalKeydown = (event: KeyboardEvent) => {
            if (["Tab", "Alt", "Shift", "Control"].includes(event.key)) {
                return;
            }
            if (focusTyping) {
                if (inputRef.current && document.activeElement !== inputRef.current) {
                    inputRef.current.focus();
                    
                }
            }
        };
    
        window.addEventListener("keydown", handleGlobalKeydown);
    
        return () => {
            window.removeEventListener("keydown", handleGlobalKeydown);
        };
    }, [focusTyping]);

    return (
        <div id="Tracky">
            <div>
                <input
                    ref={inputRef} 
                    type="text"
                    id="search-order"
                    placeholder="שם לקוח / מספר הזמנה / פריט"
                    value={searchValue}
                    onChange={handleInputChange}
                />
            </div>
            {/* ************** Search Results Area ************** */}
            <div className="tables-wrapper">
                {searchedOrders.length > 0 ? (
                    <div className="table-container">
                        <h3 className="branchHeader">תוצאות חיפוש</h3>
                        <table className="orders-table">
                            <thead>
                                <tr>
                                    <th className="orderNumber">מספר הזמנה</th>
                                    <th className="pickupBranch">סניף איסוף</th>
                                    <th className="customerName">שם לקוח</th>
                                    <th className="orderDescription">תיאור הזמנה</th>
                                    <th className="pickupDate">תאריך איסוף</th>
                                    <th className="amountCol">מחיר הזמנה</th>
                                    <th className="statusCol">מוכן / לא מוכן</th>
                                    <th className="editButton">מידע נוסף</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchedOrders.map((order) => (
                                    <tr key={order.id} className={isReadyNotPaid(order) ? "ready_not_paid" : order.is_ready_for_pickup ? "ready_line" : "not_ready_line"}>
                                        <td className="orderNumber">{order.order_number}</td>
                                        <td className="pickupBranch">{hebBranches[order.pickup_branch]}</td>
                                        <td className="customerName">{order.customer_name}</td>
                                        <td className="orderDescription">
                                            <div className="orderDescriptionDiv ">
                                                {order.description}
                                            </div>
                                        </td>
                                        <td className="pickupDate">{formatDate(order.pickup_date)}</td>
                                        <td className="amountCol">{Number(order.total_price).toLocaleString("en-US")} ₪</td>
                                        <td className="statusCol">
                                            <div className="statusColDiv">
                                                {order.is_paid ? "מוכן ושולם" : isReadyNotPaid(order) ? "מוכן - לא שולם" : order.is_ready_for_pickup
                                                    ? "מוכן"
                                                    : order.transfer && !order.is_transferred
                                                        ? "העברה מ" + hebBranches[order.transfer_branch] + " ל" + hebBranches[order.pickup_branch]
                                                        : "לא מוכן לאיסוף"}
                                            </div>
                                        </td>
                                        <td className="editButton">
                                            <i className="fa fa-info-circle" onClick={() => showEditOrder(order)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : searchValue.length > 2 ? (
                    <div className="table-container">
                        <h3 className="branchHeader">אין תוצאות חיפוש</h3>
                    </div>
                ) :
                    // ************** Orders Area **************
                    (Object.entries(orders).map(([branchKey, branchOrders]) => (
                        branchOrders.length > 0 && (
                            <div key={branchKey} className={"table-container " + (branchKey === 'urgent' ? "urgentOrders" : "")}>
                                <h3 className="branchHeader">{getBranchName(branchKey)}</h3>
                                <table className="orders-table">
                                    <thead>
                                        <tr>
                                            <th className="orderNumber">מספר הזמנה</th>
                                            <th className="customerName">שם לקוח</th>
                                            <th className="orderDescription">תיאור הזמנה</th>
                                            <th className="pickupDate">תאריך איסוף</th>
                                            <th className="amountCol">מחיר הזמנה</th>
                                            <th className="statusCol">מוכן / לא מוכן</th>
                                            <th className="editButton">מידע נוסף</th>
                                            {branchKey === 'urgent' && (
                                                    <th>סניף איסוף</th>
                                                )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {branchOrders.map((order) => (
                                            <tr key={order.id} className={isReadyNotPaid(order) ? "ready_not_paid" : order.is_ready_for_pickup ? "ready_line" : "not_ready_line"}>
                                                <td className="orderNumber">{order.order_number}</td>
                                                <td className="customerName">
                                                    <div className="customerNameDiv">
                                                        {order.customer_name}
                                                    </div>
                                                </td>
                                                <td className="orderDescription">
                                                    <div className="orderDescriptionDiv ">
                                                        {order.description}
                                                    </div>
                                                </td>
                                                <td className="pickupDate">{formatDate(order.pickup_date)}</td>
                                                <td className="amountCol">{Number(order.total_price).toLocaleString("en-US")} ₪</td>
                                                <td className="statusCol">
                                                    <div className="statusColDiv">
                                                        {order.is_paid ? "מוכן ושולם" : isReadyNotPaid(order) ? "מוכן - לא שולם" : order.is_ready_for_pickup
                                                            ? "מוכן"
                                                            : order.transfer && !order.is_transferred
                                                                ? "העברה מ" + hebBranches[order.transfer_branch] + " ל" + hebBranches[order.pickup_branch]
                                                                : "לא מוכן לאיסוף"}
                                                    </div>
                                                </td>
                                                <td className="editButton">
                                                    <i className="fa fa-info-circle" onClick={() => showEditOrder(order)} />
                                                </td>
                                                {branchKey === 'urgent' && ( 
                                                    <td ><div className="statusColDiv">
                                                        {getBranchName(branchMap[order.pickup_branch])}
                                                    </div></td>
                                                )}

                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        )
                    )))}
            </div>
        </div>
    );
}

export default Tracky;
