import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../../AuthArea/Login/Login";
import Logout from "../../AuthArea/Logout/Logout";
import Home from "../../HomeArea/Home/Home";
import NotFound404 from "../NotFound404/NotFound404";
import ProfileDetails from "../../AuthArea/ProfileDetails/ProfileDetails";
import EditProfile from "../../AuthArea/EditProfile/EditProfile";
import Tracky from "../../Tracky/Tracky";
import AddOrder from "../../AddOrder/AddOrder";
import EditOrder from "../../EditOrder/EditOrder";
import ToDo from "../../ToDo/ToDo";

function Routing(): JSX.Element {

	return (
		<div className="Routing">
			<AddOrder />
			<EditOrder />
			<Routes>
				<Route path="/" element={<Navigate to="/home" />} />
				<Route path="/home" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/user_profile/" element={<ProfileDetails />} />
				<Route path="/edit_profile/" element={<EditProfile />} />

				<Route path="/todo" element={<ToDo />} />
				<Route path="/tracky" element={<Tracky />} />
				<Route path="/*" element={<NotFound404 />} />
			</Routes>
		</div>
	);
}

export default Routing;
