import "./Login.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserModel from "../../../Models/UserModel";
import { UserContext } from "../../../Redux/UserContext";
import jwtDecode from "jwt-decode";
import authFunctions from "../../../Services/AuthFunctions";
import { useDispatch } from "react-redux";
import { fetchOrders } from "../../../Redux/slicers/ordersSlicer";
import { OrdersDispatch, TasksDispatch } from "../../../Redux/OrdersContext";
import { fetchTasks } from "../../../Redux/slicers/tasksSlicer";


interface TokenPayload {
	user_id: number;
}

function Login(): JSX.Element {
	const context = useContext(UserContext);

	const { register, handleSubmit } = useForm<UserModel>();
	const navigate = useNavigate();
	const [formErrors, setFormErrors] = useState("");
	const orderDispatch: OrdersDispatch = useDispatch();
	const tasksDipsatch: TasksDispatch = useDispatch();

	// If user is already logged in, redirect to home page
	useEffect(() => {
		if (context.user) {
			navigate("/home");
		}
	}, [context.user, navigate]);

	async function send(credentials: UserModel) {
		try {
			if (credentials.password === "" || credentials.username === "") {
				setFormErrors("Please fill Username and Password.");
				return;
			} else {
				setFormErrors("");
				await authFunctions.login(credentials).then(() => {
					const accessToken = JSON.parse(
						localStorage.getItem("tokens")
					).access;
					const container = jwtDecode<TokenPayload>(accessToken);
					authFunctions
						.getUserById(container.user_id)
						.then((user) => (context.user = user))

						.finally(() => {
							authFunctions
								.getUserProfileById(context.user.id)
								.then((profile) => (context.profile = profile))
								.then(() => {
									orderDispatch(fetchOrders());
									tasksDipsatch(fetchTasks());
								})
								.finally(() => navigate("/home"));
						});
				});
			}
		} catch (err: any) {
			setFormErrors(err.message);
		}
	}

	return (
		<div className="Login">
			<h2>התחברות</h2>
			<br />
			<form onSubmit={handleSubmit(send)}>
				<div className="form-floating">
					<input
						type="text"
						className="form-control"
						{...register("username")}
					/>
					<label>שם משתמש</label>
				</div>

				<div className="form-floating">
					<input
						type="password"
						className="form-control"
						{...register("password")}
					/>
					<label>סיסמא</label>
				</div>
				{formErrors && (
					<span style={{ color: "red" }}>{formErrors}</span>
				)}
				<button type="submit" className="button-29">
					התחבר
				</button>
			</form>
		</div>
	);
}

export default Login;
