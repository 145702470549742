import "./Footer.css";

function Footer(): JSX.Element {

    const date = new Date().toLocaleDateString("he-IL");

    return (
        <div className="Footer">
            <p>All Rights Reserved To Nir Ben Giat &copy; {date}</p>
        </div>
    );
}

export default Footer;
