import { configureStore } from "@reduxjs/toolkit";
import ordersReducer from "./slicers/ordersSlicer";
import tasksReducer from "./slicers/tasksSlicer";

export const ordersContext = configureStore({
	reducer: {
		orders: ordersReducer,
		tasks: tasksReducer,
	},
});

export type OrdersState = ReturnType<typeof ordersContext.getState>;
export type OrdersDispatch = typeof ordersContext.dispatch;

export type TasksState = ReturnType<typeof ordersContext.getState>;
export type TasksDispatch = typeof ordersContext.dispatch;
