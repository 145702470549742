import { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../../Assets/Images/lotusicon.png";
import AuthMenu from "../../AuthArea/AuthMenu/AuthMenu";
import "./navbar.css";
import { UserContext } from "../../../Redux/UserContext";
import { useDispatch } from "react-redux";
import { setInputFocusTyping } from "../../../Redux/slicers/ordersSlicer";
import { OrdersDispatch } from "../../../Redux/OrdersContext";

function Navbar() {
	const [sideOpened, setSideOpened] = useState(false);
	const dispatch: OrdersDispatch = useDispatch();
	const handleClick = () => setSideOpened(!sideOpened);
	const handleClickOutside = (event: MouseEvent) => {
		const navContainer = document.getElementById("navcontainer");
		const mobileButton = document.getElementById("mobile");
		if (
			navContainer &&
			!navContainer.contains(event.target as Node) &&
			!mobileButton?.contains(event.target as Node)
		) {
			setSideOpened(false);
		}
	};

	const { pathname } = useLocation();
	const context = useContext(UserContext);

	useEffect(() => {

	}, [pathname]);



	useEffect(() => {
		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const showAddOrder = () => {
		const addOrderDiv = document.querySelector('.AddOrder');
		addOrderDiv.classList.add('visible');
		dispatch(setInputFocusTyping(false));
	};

	const navbarClass = sideOpened ? "navbar active" : "navbar";

	return (
		<nav className="navbar-header">
			<div id="mobile" onClick={handleClick}>
				<i
					id="bar"
					className={sideOpened ? "fas fa-times" : "fas fa-bars"}
				></i>
			</div>
			<div id="navcontainer">
				<NavLink to={"/home"}>
					<img id="logo-pic" src={logo} alt="Logo" />
				</NavLink>
				{context.user ? (
					<ul id="navbar" className={navbarClass}>
						<li>
							<NavLink to="/home">בית</NavLink>
						</li>
						<li>
							<NavLink to="/tracky">ניהול הזמנות</NavLink>
						</li>
						<li>
							<button className="showorderbutton" onClick={showAddOrder}>הוספת הזמנה</button>
						</li>
						<li>
							<NavLink to="/todo">משימות לביצוע</NavLink>
						</li>
						<div className="authMenu">
							<AuthMenu />
						</div>
					</ul>
				) : ('')}
			</div>
		</nav>
	);
}

export default Navbar;
