import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import OrderModel from "../../Models/OrderModel";
import config from "../../Utils/Config";
import axios from "axios";

const today = new Date();
const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

interface OrdersState {
	value: OrderModel[];
	orders: {
		urgent: OrderModel[];
		big: OrderModel[];
		klb: OrderModel[];
		ice: OrderModel[];
		red: OrderModel[];
		tlt: OrderModel[];
		eilat_delivery: OrderModel[];
		delivery: OrderModel[];
		main: OrderModel[];
	};
	sorting: {
		sort:
			| "order_date"
			| "total_price"
			| "pickup_date"
			| "description"
			| "status";
		order: "ascending" | "descending";
	};
	pickedOrder: OrderModel | null;
	inputFocusTyping: boolean;
}

const initialState: OrdersState = {
	value: [],
	orders: {
		urgent: [],
		big: [],
		klb: [],
		ice: [],
		red: [],
		tlt: [],
		eilat_delivery: [],
		delivery: [],
		main: [],
	},
	sorting: { sort: "status", order: "ascending" },
	pickedOrder: null,
	inputFocusTyping: true,
};

const branchMap: Record<number, keyof OrdersState["orders"]> = {
	0: "urgent",
	1: "big",
	2: "klb",
	3: "ice",
	4: "red",
	5: "tlt",
	6: "eilat_delivery",
	7: "delivery",
	8: "main", // main warehouse
};

const sortOrders = (
	orders: OrderModel[],
	sorting: {
		sort:
			| "order_date"
			| "total_price"
			| "pickup_date"
			| "description"
			| "status";
		order: "ascending" | "descending";
	}
) => {
	const sortedOrders = [...orders]; // Create a copy of the expenses array to avoid mutating the original array

	switch (sorting.sort) {
		case "order_date":
			sortedOrders.sort((a, b) => {
				const dateA = new Date(a.order_date);
				const dateB = new Date(b.order_date);
				return sorting.order === "ascending"
					? dateA.getTime() - dateB.getTime()
					: dateB.getTime() - dateA.getTime();
			});
			break;
		case "pickup_date":
			sortedOrders.sort((a, b) => {
				const dateA = new Date(a.pickup_date);
				const dateB = new Date(b.pickup_date);
				return sorting.order === "ascending"
					? dateA.getTime() - dateB.getTime()
					: dateB.getTime() - dateA.getTime();
			});
			break;
		case "total_price":
			sortedOrders.sort((a, b) => {
				const amountA =
					typeof a.total_price === "string"
						? parseFloat(a.total_price)
						: a.total_price;
				const amountB =
					typeof b.total_price === "string"
						? parseFloat(b.total_price)
						: b.total_price;
				return sorting.order === "ascending"
					? amountA - amountB
					: amountB - amountA;
			});
			break;
		case "description":
			sortedOrders.sort((a, b) => {
				return sorting.order === "ascending"
					? a.description.localeCompare(b.description)
					: b.description.localeCompare(a.description);
			});
			break;
		case "status":
			sortedOrders.sort((a, b) => {
				// First, compare by is_ready_for_pickup status
				const statusComparison =
					sorting.order === "descending"
						? a.is_ready_for_pickup === b.is_ready_for_pickup
							? 0
							: a.is_ready_for_pickup
							? -1
							: 1
						: b.is_ready_for_pickup === a.is_ready_for_pickup
						? 0
						: b.is_ready_for_pickup
						? -1
						: 1;

				// If the status is the same, compare by pickup_date
				if (statusComparison !== 0) {
					return statusComparison;
				}

				// Secondary comparison by pickup_date
				const dateA = new Date(a.pickup_date);
				const dateB = new Date(b.pickup_date);
				return sorting.order === "ascending"
					? dateA.getTime() - dateB.getTime()
					: dateB.getTime() - dateA.getTime();
			});
			break;
		default:
			break;
	}

	return sortedOrders;
};

function checkUrgent(order: OrderModel) {
	const twoDaysFromToday = new Date();
	twoDaysFromToday.setDate(twoDaysFromToday.getDate() + 2);
	if (new Date(order.pickup_date) < tomorrow && !order.is_ready_for_pickup) {
		return true;
	}

	if (
		order.transfer &&
		!order.is_transferred &&
		new Date(order.pickup_date) < twoDaysFromToday
	) {
		return true;
	}

	return false;
}

function updateOrderInBranch(
	state: OrdersState,
	updatedOrder: OrderModel,
	oldOrderKey: keyof OrdersState["orders"]
) {
	const newOrderKey = (branchMap[updatedOrder.pickup_branch] ||
		"main") as keyof OrdersState["orders"];
	const urgentOrderKey: keyof OrdersState["orders"] = "urgent"; // Assuming "urgent" is the key for urgent orders

	// Determine the current key for the updated order
	const currentKey = checkUrgent(updatedOrder) ? urgentOrderKey : newOrderKey;

	// Remove the order from the old branch
	const oldOrders = state.orders[oldOrderKey];
	const orderIndex = oldOrders.findIndex(
		(order) => order.id === updatedOrder.id
	);
	if (orderIndex !== -1) oldOrders.splice(orderIndex, 1);

	// Remove the order from the urgent branch if it was previously marked urgent
	if (oldOrderKey !== urgentOrderKey) {
		const urgentOrders = state.orders[urgentOrderKey];
		const urgentIndex = urgentOrders.findIndex(
			(order) => order.id === updatedOrder.id
		);
		if (urgentIndex !== -1) urgentOrders.splice(urgentIndex, 1);
	}

	// Add the updated order to the correct branch
	const targetOrders = state.orders[currentKey];
	targetOrders.push(updatedOrder);

	// Sort the orders in the updated branch
	state.orders[currentKey] = sortOrders(targetOrders, state.sorting);
}

export const ordersSlice = createSlice({
	name: "orders",
	initialState,
	reducers: {
		setInputFocusTyping: (state, action: PayloadAction<boolean>) => {
			state.inputFocusTyping = action.payload;
			console.log(state.inputFocusTyping);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				fetchOrders.fulfilled,
				(state, action: PayloadAction<OrderModel[]>) => {
					// Clear the orders object
					const newOrders = { ...state.orders };
					Object.keys(state.orders).forEach((key) => {
						newOrders[key as keyof OrdersState["orders"]] = [];
					});

					// Sort and categorize orders
					sortOrders(action.payload, state.sorting).forEach(
						(order) => {
							if (order.is_paid) {
								return;
							} else if (checkUrgent(order)) {
								newOrders.urgent.push(order);
							} else {
								const orderKey =
									branchMap[order.pickup_branch] || "main";
								newOrders[orderKey].push(order);
							}
						}
					);

					// Replace the orders state with the new one
					state.orders = newOrders;
					state.value = sortOrders(action.payload, state.sorting);
				}
			)
			.addCase(
				addOrder.fulfilled,
				(state, action: PayloadAction<OrderModel>) => {
					const newOrder = action.payload;
					state.value = [...state.value, newOrder];

					// Determine the branch of the new order and add it immutably
					const orderKey =
						branchMap[newOrder.pickup_branch] || "main";

					// Add the new order to the branch and then sort using sortOrders
					state.orders = {
						...state.orders,
						[orderKey]: sortOrders(
							[...state.orders[orderKey], newOrder], // Existing orders + new order
							state.sorting // Pass the current sorting criteria from state
						),
					};

					// Check if the new order is urgent and categorize it
					if (checkUrgent(newOrder)) {
						state.orders.urgent = sortOrders(
							[...state.orders.urgent, newOrder], // Add new order to urgent orders
							state.sorting // Pass the same sorting criteria for consistency
						);
					}
				}
			)

			.addCase(
				updateOrder.fulfilled,
				(state, action: PayloadAction<OrderModel>) => {
					const updatedOrder = action.payload;

					// Find the index of the order in state.value
					const index = state.value.findIndex(
						(order) => order.id === updatedOrder.id
					);
					if (index === -1) {
						console.error(
							"Order not found in state.value for update:",
							updatedOrder.id
						);
						return;
					}

					// Capture the old branch before updating the order
					const oldOrderKey = (branchMap[
						state.value[index].pickup_branch!
					] || "main") as keyof OrdersState["orders"];

					// Update the order in state.value
					state.value[index] = updatedOrder;

					// Sort the entire state.value array after the update
					state.value = sortOrders(state.value, state.sorting);

					// Remove the order from the old branch and add it to the new branch
					updateOrderInBranch(state, updatedOrder, oldOrderKey);
				}
			)
			.addCase(
				deleteOrder.fulfilled,
				(state, action: PayloadAction<number>) => {
					const id = action.payload;
					const deletedOrder = state.value.find(
						(order) => order.id === id
					);

					if (deletedOrder) {
						const orderKey =
							branchMap[deletedOrder.pickup_branch] || "main";

						// Remove from both value array and the correct branch immutably
						state.value = state.value.filter(
							(order) => order.id !== id
						);
						state.orders = {
							...state.orders,
							[orderKey]: state.orders[orderKey].filter(
								(order) => order.id !== id
							),
						};
						// Also check if the order is in urgent orders and remove it if present
						state.orders.urgent = state.orders.urgent.filter(
							(order) => order.id !== id
						);
					}
				}
			)

			.addCase(
				changeSort.fulfilled,
				(
					state,
					action: PayloadAction<{
						sort:
							| "order_date"
							| "total_price"
							| "pickup_date"
							| "description";
						order: "ascending" | "descending";
					}>
				) => {
					state.sorting = action.payload;
					state.value = sortOrders(state.value, state.sorting);
				}
			)
			.addCase(
				changePickedOrder.fulfilled,
				(state, action: PayloadAction<OrderModel | null>) => {
					state.pickedOrder = action.payload;
				}
			);
	},
});

export const fetchOrders = createAsyncThunk("orders/fetchOrders", async () => {
	const response = await axios.get(`${config.ordersUrl}`);
	return response.data;
});

export const addOrder = createAsyncThunk(
	"orders/addOrder",
	async (order: OrderModel) => {
		console.log(order);
		const response = await axios.post(`${config.ordersUrl}`, order);
		console.log(response.data);
		return response.data;
	}
);

export const updateOrder = createAsyncThunk(
	"orders/updateOrder",
	async (order: OrderModel) => {
		const response = await axios.put<OrderModel>(
			config.ordersUrl + order.id,
			order
		);
		return response.data;
	}
);

export const deleteOrder = createAsyncThunk(
	"orders/deleteOrder",
	async (id: number) => {
		await axios.delete(config.ordersUrl + id);
		return id;
	}
);

export const changeSort = createAsyncThunk(
	"orders/changeSort",
	async (sorting: {
		sort: "order_date" | "total_price" | "pickup_date" | "description";
		order: "ascending" | "descending";
	}) => {
		return sorting;
	}
);

export const changePickedOrder = createAsyncThunk(
	"orders/changePickedOrder",
	async (order: OrderModel | null) => {
		return order;
	}
);

export const { setInputFocusTyping } = ordersSlice.actions;

export default ordersSlice.reducer;
