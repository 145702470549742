class Config {
	public supportEmail = "nirbengiat@gmail.com";
	public supportPhone = "+972506789718";
	public supportPage = "";
	
	public serverUrl = "https://rafi-tracky-backend-ooafk.ondigitalocean.app/";

	public loginUrl = this.serverUrl + "accounts/token/";
	public usersUrl = this.serverUrl + "users/";
	public usersProfilesUrl = this.serverUrl + "user_profile/";

	public ordersUrl = this.serverUrl + "orders/";
	public tasksUrl = this.serverUrl + "tasks/";
}

const config = new Config();

export default config;
