import { useContext, useEffect, useState } from "react";
import "./AddOrder.css";
import { UserContext } from "../../Redux/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { OrdersDispatch, OrdersState } from "../../Redux/OrdersContext";
import { useForm } from "react-hook-form";
import OrderModel from "../../Models/OrderModel";
import { addOrder, setInputFocusTyping } from "../../Redux/slicers/ordersSlicer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

function AddOrder(): JSX.Element {
    const context = useContext(UserContext);
    const dispatch: OrdersDispatch = useDispatch();
    const allOrders = useSelector((state: OrdersState) => state.orders.value);
    const [totalPrice, setTotalPrice] = useState("");
    const [pickupDate, setPickupDate] = useState(new Date());
    const [orderDate, setOrderDate] = useState(new Date());
    const [isTransferChecked, setIsTransferChecked] = useState(false);
    const { register, handleSubmit, formState, reset, setError } = useForm<OrderModel>();
    const navigate = useNavigate();

    function handleTotalPriceChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.replace(/[^\d.]/g, "");
        setTotalPrice(value);
        if (formState.errors.total_price) {
            formState.errors.total_price.message = "";
        }
    }

    function handleTransferChange() {
        setIsTransferChecked(!isTransferChecked);
    }

    // Disable input fields in date picker and handle click outside of the form
    useEffect(() => {
        document.querySelectorAll(".react-datepicker__input-container input").forEach((input) => { (input as HTMLInputElement).readOnly = true });
        const backgroundHandler = document.getElementById("AddOrder");
        const handleClick = (e: MouseEvent) => {
            if (e.target === backgroundHandler) {
                // Remove the 'visible' class but wait for the transition to finish before hiding it
                closeAddOrder();
            }
        };
        const escapeKey = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                closeAddOrder();
            }
        };
        if (backgroundHandler) {
            backgroundHandler.addEventListener("click", handleClick);
        }
        window.addEventListener("keydown", escapeKey);
        // Cleanup event listener on component unmount
        return () => {
            if (backgroundHandler) {
                backgroundHandler.removeEventListener("click", handleClick);
            }
        };
    });

    function closeAddOrder() {
        const grandParentDiv = document.getElementById("AddOrder");
        grandParentDiv.classList.remove("visible");
        dispatch(setInputFocusTyping(true));
    }


    async function send(order: OrderModel) {
        order.total_price = parseFloat(totalPrice);
        order.user = context.user ? context.user.id : 0;
        order.is_transferred = !isTransferChecked ? false : null;
        order.pickup_date = pickupDate.toLocaleDateString('en-CA'); // 'en-CA' format is yyyy-mm-dd
        order.order_date = orderDate.toLocaleDateString('en-CA');

        try {
            if (allOrders.some((o) => o.order_number === order.order_number)) {
                setError("order_number", { type: "manual", message: "מספר הזמנה כבר קיים" });
                return;
            }
            dispatch(addOrder(order));
            reset();
            setTotalPrice("");
            setIsTransferChecked(false);
            closeAddOrder();
            navigate("/tracky");
        } catch (err) {
            alert("Failed to add order: " + err);
        }
    }

    return (
        <div className="AddOrder" id="AddOrder">
            <div className="AddOrderC">
                <h3>הזמנה חדשה</h3>
                <i className="fa fa-times" onClick={closeAddOrder}></i>
                <form>
                    {formState.errors.order_number ? <div className="error-message">{formState.errors.order_number.message}</div> : null}
                    <div className="form-row">
                        {/* Order Number */}
                        <div className="input-data">
                            <input type="text" required {...register("order_number", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>מספר הזמנה</label>
                        </div>
                        {/* Pick Up Date */}
                        <div className="input-data">
                            <span className="date-label">תאריך איסוף</span>
                            <DatePicker
                                selected={pickupDate}
                                onChange={(date) => (setPickupDate(date))}
                                dateFormat="dd/MM"
                                className="react-datepicker__input-container"
                            />
                            <input
                                type="hidden"
                                {...register("pickup_date", { required: true })}
                                value={pickupDate.toISOString().split("T")[0]}
                            />
                        </div>
                        {/* Pick Up Branch */}
                        <div className="input-data">
                            <select required className="select-style" {...register("pickup_branch", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })}>
                                <option value="1">ביג</option>
                                <option value="2">דיסק קלאב</option>
                                <option value="3">אייסמול</option>
                                <option value="4">רד</option>
                                <option value="5">טיילת</option>
                                <option value="6">משלוח לבית הלקוח באילת</option>
                                <option value="7">משלוח לבית הלקוח </option>
                                <option value="8">מחסן ראשי </option>
                            </select>
                            <label>סניף איסוף</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Customer Name */}
                        <div className="input-data">
                            <input type="text" required {...register("customer_name", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>שם לקוח</label>
                        </div>
                        {/* Customer Phone */}
                        <div className="input-data">
                            <input type="text" required {...register("customer_phone", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>טלפון לקוח</label>
                        </div>
                        {/* Customer Email */}
                        <div className="input-data">
                            <input type="text" required {...register("customer_email", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>אימייל לקוח</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Description */}
                        <div className="input-data">
                            <input type="text" required {...register("description", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })} />
                            <div className="underline"></div>
                            <label>תיאור הזמנה</label>
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Total Price */}
                        <div className="input-data">
                            <input type="text" required value={totalPrice} {...register("total_price", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                },
                                onChange: (e) => { handleTotalPriceChange(e) },
                            })} />
                            <div className="underline"></div>
                            <label>סכום כולל</label>
                        </div>
                        {/* Order Date */}
                        <div className="input-data">
                            <span className="date-label">תאריך ביצוע הזמנה</span>
                            <DatePicker
                                selected={orderDate}
                                onChange={(date) => (setOrderDate(date))}
                                dateFormat="dd/MM"
                                className="react-datepicker__input-container"
                            />
                            <input
                                type="hidden"
                                {...register("order_date", { required: true })}
                                value={orderDate.toISOString().split("T")[0]}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Is Paid */}
                        <div className="input-checkbox">
                            <label>האם שולם</label><br />
                            <input type="checkbox" className="checkbox-style" {...register("is_paid")} />
                        </div>
                        <br />
                        {/* Is Transfer */}
                        <div className="input-checkbox">
                            <label>האם צריך לעבור מסניף אחר</label><br />
                            <input type="checkbox" className="checkbox-style" onClick={() => handleTransferChange()} {...register("transfer")} />
                        </div>
                    </div>

                    {!isTransferChecked ? <div className="padding-row"></div> : null}
                    <div className={isTransferChecked ? "form-row" : "hidden"}>
                        {/* Transfer Branch */}
                        <div className="input-data">
                            <select required className="select-style" {...register("transfer_branch", {
                                required: {
                                    value: true,
                                    message: "שדה חובה"
                                }
                            })}>
                                <option value="1">ביג</option>
                                <option value="2">דיסק קלאב</option>
                                <option value="3">אייסמול</option>
                                <option value="4">רד</option>
                                <option value="5">טיילת</option>
                                <option value="6">משלוח לבית הלקוח באילת</option>
                                <option value="7">משלוח לבית הלקוח </option>
                                <option value="8">מחסן ראשי </option>
                            </select>
                            <label>סניף שולח</label>
                        </div>
                        {/* Is Transferred */}
                        <div className="input-checkbox">
                            <label>האם הועבר</label><br />
                            <input type="checkbox" className="checkbox-style" {...register("is_transferred")} />
                        </div>
                    </div>

                    <div className="form-row">
                        {/* Is Ready For Pickup */}
                        <div className="input-checkbox">
                            <label>האם מוכן לאיסוף</label><br />
                            <input type="checkbox" className="checkbox-style larger" {...register("is_ready_for_pickup")} />
                        </div>
                    </div>
                    <div className="form-row">
                        <br />
                        <div className="input-data">
                            <button type="submit" className="button-29" onClick={handleSubmit((data) => send(data))}>הוסף</button>
                        </div>
                    </div>

                </form>
            </div>
        </div >
    );
}

export default AddOrder;
